<template>
  <div class="row">
    <div class="col-md-6">
      <Accordion id="changeEmail" header="content.change_email">
        <form @submit.prevent="changeEmail">
          <labeled-input id="email" type="email" label="form.new_email" :form="formEmail"/>
          <labeled-input id="password" type="password" label="form.your_password" :form="user"/>
          <button type="submit" class="btn btn-primary">{{ $t('form.update') }}</button>
        </form>
      </Accordion>
    </div>
    <div class="col-md-6">
      <Accordion id="changeEmail" header="content.change_password">
        <form @submit.prevent="changePassword">
          <labeled-input id="old_password" type="password" label="form.old_password" :form="formEmail"/>
          <labeled-input id="new_password" type="password" label="form.new_password" :form="user"/>
          <button type="submit" class="btn btn-primary">{{ $t('form.update') }}</button>
        </form>
      </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from "../components/Accordion";
import LabeledInput from "../components/LabeledInput";
import {mapActions, mapGetters} from "vuex";
import {updatePassword, updateEmail} from "firebase/auth";
// import {auth} from "../firebase/config";

export default {
  name: "Settings",
  components: {LabeledInput, Accordion},
  data() {
    return {
      formEmail: {
        email: '',
        old_password: ''
      },
      user: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      'authenticated': 'auth/authenticated'
    }),
  },
  mounted() {
    // console.log(this.authenticated);
    this.formEmail.email = this.authenticated.email;
    this.user.email = this.authenticated.email;
  },
  methods: {
    ...mapActions({
      'login': 'auth/login'
    }),
    changeEmail() {
      // console.log(this.user);
      this.login(this.user).then((res) => {
        if (res.code) {
          alert("Error : " + res.code);
        } else {
          updateEmail(res.user, this.formEmail.email).then(() => {
            location.reload();
          }).catch((error) => {
            alert("Error : " + error.code);
          });
        }
      });
    },
    changePassword() {
      // console.log(this.user);
      this.login(this.user).then((res) => {
        if (res.code) {
          alert("Error : " + res.code);
        } else {
          updatePassword(res.user, this.formEmail.old_password).then(() => {
            location.reload();
          }).catch((error) => {
            alert("Error : " + error.code);
          });
        }
      });
    }
  }

}
</script>

<style scoped>

</style>